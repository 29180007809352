import { twJoin, twMerge } from '@troon/tailwind-preset/merge';
import { mergeProps } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import type { IconComponent } from '@troon/icons';
import type { ParentProps } from 'solid-js';

type Props = ParentProps<{
	appearance?: 'default' | 'danger' | 'brand' | 'dark';
	class?: string;
	icon?: IconComponent;
}>;

export function MessageBar(input: Props) {
	const props = mergeProps({ appearance: 'default' }, input);

	return (
		<div
			role="status"
			class={twMerge(
				twJoin(
					'flex items-start gap-2 rounded px-4 py-3',
					props.appearance === 'danger' && 'bg-red-100 text-red-600',
					props.appearance === 'brand' && 'bg-brand-100 text-brand-600',
					props.appearance === 'default' && 'bg-neutral-100',
					props.appearance === 'dark' && 'bg-neutral-300',
				),
				props.class,
			)}
		>
			<Dynamic component={props.icon} class="relative top-px shrink-0" />
			{props.children}
		</div>
	);
}
